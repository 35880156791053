import WOW from 'wowjs';
import Slider from "react-slick";
import EventBus from 'eventing-bus';
import Modal from '@mui/material/Modal';
import AudioPlayer from 'react-h5-audio-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState, useRef } from 'react';

import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const tracks = [
    { url: "https://assets-softtik.s3.us-east-2.amazonaws.com/The+Upbeat.mp3" },
    { url: "https://assets-softtik.s3.us-east-2.amazonaws.com/Small+Dog+Bark+4.mp3" },
]
const address = 'TAPxMfHHgFyZMHuuz8wL56pi54eB2xkK2q';

const Home = () => {
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    return (
        <div className='knuckles-page'>
            <div>
                <Navbar />
                {/* Banner */}
                <section className="banner-section" id="home">
                    <div className='center-img'><img src={require("../../static/images/center-kukles-img.png")} alt="" /></div>
                    <div className='tron-1'><img src={require("../../static/images/tron-1.png")} alt="" /></div>
                    <div className='tron-2'><img src={require("../../static/images/tron-2.png")} alt="" /></div>
                    <div className='tron-3'><img src={require("../../static/images/tron-3.png")} alt="" /></div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className='left-img wow zoomIn'>
                                    <img src={require("../../static/images/left-banner-img.png")} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className='banner-right-area wow zoomIn'>
                                    <div className='right-text-area'>
                                        <h1>knuckles</h1>
                                        <p>Find Da Wae with $KNUCKLES – Or Get Lost! </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Toknomics Section */}
                <section className='toknomics-sec' id="toknomics-sec">
                    <div className='tron-1'><img src={require("../../static/images/tron-4.png")} alt="" /></div>
                    <div className='tron-2'><img src={require("../../static/images/tron-5.png")} alt="" /></div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-7'>
                                <div className='sec-title wow zoomIn'>
                                    <h2>Tokenomic</h2>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='toknomics-inner'>
                                    <div className='left-img-area'>
                                        <img className='charctor-img' src={require("../../static/images/toknomics-left-img-2.png")} alt="" />
                                        <img className='bottom-img'  src={require("../../static/images/toknomics-left-img-1.png")} alt="" />
                                    </div>
                                    <div className='toknomics-right'>
                                        <div className='top-img'>
                                            <img src={require("../../static/images/toknomics-tree.png")} alt="" />
                                        </div>
                                        <div className='toknomics-box toknomics-box-1 wow fadeInLeft'>
                                            <div className='text-box'>
                                                <h4>Token Address</h4>
                                                <p>{address.substring(0, 10)}.....{address.substring(address.length - 10, address.length)}</p>
                                                {/* <CopyToClipboard text={address} onCopy={() => EventBus.publish('success', 'Address Copied!')}>
                                                    <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                </CopyToClipboard> */}
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-2 wow fadeInLeft'>
                                            <div className='text-box'>
                                                <h4>100% LP Burnt</h4>
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-3 wow fadeInDown'>
                                            <div className='text-box'>
                                                <h4>Total Supply</h4>
                                                <p>100 billion</p>
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-4 wow fadeInUp'>
                                            <div className='text-box'>
                                                <h4>Mint Authority Revoked</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Buy Section */}
                <section className="buy-section" id="buy">
                    <div className='tron-2  wow zoomIn'><img src={require("../../static/images/tron-5.png")} alt="" /></div>
                    <div className="container-fluid">
                        <div className="row">                            
                            <div className="col-12">
                                <div className='text-box wow fadeInLeft'>
                                    <h3>"Do you kno da wae to be on <span>top</span> of <br />the meme world?"</h3>
                                </div>
                                <div className='left-img-area wow fadeInRight'>
                                    <img src={require("../../static/images/coins.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Join Section */}
                <section className='join-sec' id="joinme">
                    <div className='tron-1 wow zoomIn'><img src={require("../../static/images/tron-6.png")} alt="" /></div>
                    <div className='tron-2 wow zoomIn'><img src={require("../../static/images/tron-7.png")} alt="" /></div>
                    <div className='tron-3 wow zoomIn'><img src={require("../../static/images/tron-8.png")} alt="" /></div>
                    <div className='auto-container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='sec-title'>
                                    <h2>join the community</h2>
                                </div>
                               <div className='footer-bottom'>
                                <a className='footer-icon' href='http://t.co/TronDogOfficial' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' /></a>
                                    <div className='img-area'>
                                        <img className='pe-auto' src={require("../../static/images/bottom-community-img.png")} alt="" />
                                    </div>
                                    <a className='footer-icon' href='http://x.com/TronDogOfficial' target='_blank'><img src={require('../../static/images/x.png')} alt='' /></a>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            {/* Enter Website */}
            {/* <Modal open={isEnterModal} onClose={() => setIsEnterModal(false)} className='modal-enter-website'>
                <div className='body-modal'>
                    <div className='modal-inner'>
                        <div className='left-text-area'>
                            <h1>TRON </h1>
                            <h2>DOG</h2>
                        </div>
                        {isPageLoaded
                            ? <button className='entry-btn' onClick={() => handleEnterWebsite()}>Start</button>
                            : <i class="loader"><img src={require('../../static/images/spinner.gif')} alt='' /></i>
                        }
                    </div>
                </div>
            </Modal> */}
        </div >
    );
}

export default Home;